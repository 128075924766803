<template>
  <div>
    <head-layout
      head-title="kafKa信息采集"
      :head-btn-options="headBtnOptions"

      @head-save="saveKafa"
      @head-save-back="saveAll('back')"
      @head-back="toback"
      @head-start="start"
      @head-stop="stop"
    >
    </head-layout>
    <div class="newZtFormBox" style="background-color: white">
      <el-form
        ref="step1Form"
        :rules="rules1"
        v-show="stepsActive == 0"
        :model="addform"
        label-width="120px"
      >
        <div style="height: 10px"></div>

        <el-collapse v-model="activeNames" style="margintop: 10px">
          <el-collapse-item name="1">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
              ><i
                class="el-icon-document"
                style="font-size: 20px; padding: 0 10px"
              ></i>
                源信息
              </i>
            </template>

            <el-row>
              <el-col :span="12">
                <el-form-item label="名称" prop="serverName">
                  <el-input v-model="addform.serverName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="状态" prop="status">
                  <el-select
                    filterable
                    disabled
                    v-model="addform.status"
                    placeholder="状态"
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in kafkaStatusList"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="parseInt(item.dictKey)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

<!--              <el-col :span="12">-->
<!--                <el-form-item label="版本" prop="readerDatasourceId">-->
<!--                -->
<!--                </el-form-item>-->
<!--              </el-col>-->

            </el-row>

<!--            <el-row>-->
<!--              <el-col :span="24">-->
<!--                <el-form-item label="适用环境">-->
<!--                  <el-radio-group v-model="form.resource">-->
<!--                    <el-radio label="开发"></el-radio>-->
<!--                    <el-radio label="生产"></el-radio>-->
<!--                  </el-radio-group>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row>
              <el-col :span="24">
                <el-form-item
                  prop="brokerList"
                  label="集群地址"
                >
                  <el-input
                    v-model="addform.brokerList"
                    type="textarea"
                  ></el-input>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  prop="topic"
                  label="TOPIC地址"
                >
                  <el-input
                    v-model="addform.topic"
                    type="textarea"
                  ></el-input>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="描述"
                >
                  <el-input
                    v-model="addform.describes"
                    type="textarea"
                  ></el-input>

                </el-form-item>
              </el-col>
            </el-row>

          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
              ><i
                class="el-icon-document"
                style="font-size: 20px; padding: 0 10px"
              ></i>
                目标信息
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Databasename')"
                  prop="writeDatasourceId"
                >
                  <el-select
                    filterable
                    @change="dbChangeFn1"
                    v-model="addform.writeDatasourceId"
                    :placeholder="
                      $t('OfflinedataCollection.label.Databasename')
                    "
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in dbList"
                      :key="index"
                      :label="item.desc"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Datatablename')"
                  prop="writeTable"
                >
                  <el-select
                    filterable
                    v-model="addform.writeTable"
                    @change="getColumnFn1(addform.writeTable,colmnList1)"
                    :placeholder="
                      $t('OfflinedataCollection.label.Datatablename')
                    "
                    :error="$t('OfflinedataCollection.label.Datatablename')"
                  >
                    <el-option
                      v-for="(item, index) in biaoList1"
                      :key="index"
                      :label="item.table_name"
                      :value="item.table_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>

        <el-tabs type="border-card">
          <el-tab-pane
            label="源保存字段"
          >

              <el-button
                size="small" @click="colmnListAdd">
                新增
              </el-button>
              <el-button
                size="small">
                删除
              </el-button>


            <el-form-item label-width="1">
              <el-table
                @select="selectstep0Savetable"
                ref="step0Savetable"
                :data="colmnList"
                border
                size="small"
                style="width: 100%"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  label="字段名"
                  prop="targetColumn"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.targetColumn"></el-input>
                  </template>

                </el-table-column>
                <el-table-column   label="类型"
                                   prop="sourceType">
                  <template slot-scope="scope">
                    <el-select
                      filterable
                      v-model="scope.row.sourceType"
                      @change="setKafkaMainfield(scope.row)"

                      placeholder="请选择类型"
                      :error="$t('OfflinedataCollection.label.Datatablename')"
                    >
                      <el-option
                        v-for="(item, index) in kafkaMainfieldTypesList"
                        :key="index"
                        :label="item.dictValue"
                        :value="item.dictKey"
                      ></el-option>
                    </el-select>
                  </template>

                </el-table-column>


                <el-table-column label="字段注释" prop="targetComment">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.targetComment">

                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-tab-pane>


          <el-tab-pane label="对应关系">
            <el-form-item label-width="1">
              <el-table
                ref="step2Table"
                :data="colmnList1"
                border
                size="small"
                style="width: 100%"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  label="字段名称"
                  prop="name"
                >
                </el-table-column>

                <el-table-column label="字段注释" prop="sourceComment">
                </el-table-column>

                <!-- dataType datetime  timestam-->

                <el-table-column label="对应关系" prop="duiying">

                  <template slot-scope="scope">
                    <el-select v-model="scope.row.sourceColumn" clearable @change="selectTableComm(scope.row,colmnList)">
                      <el-option
                        :label="item.targetColumn"
                        :value="item.targetColumn"
                        v-for="(item, index) in colmnList"
                        :key="index"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>



    </div>
    <div  v-for="(item, index) in kafkaChildfield" :key="index">


      <el-form
        :rules="rules1"
        v-show="stepsActive == 0"
        :model="item.form"
        label-width="120px"
      >
        <el-collapse v-model="activeNames" style="margintop: 10px">
          <el-collapse-item name="2">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
              ><i
                class="el-icon-document"
                style="font-size: 20px; padding: 0 10px"
              ></i>
              {{item.targetColumn}}目标信息
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Databasename')"
                  prop="writeDatasourceId"
                >
                  <el-select
                    filterable
                    disabled
                    @change="dbChangeFn1"
                    v-model="item.writeDatasourceId"
                    :placeholder="
                      $t('OfflinedataCollection.label.Databasename')
                    "
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in dbList"
                      :key="index"
                      :label="item.desc"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Datatablename')"
                  prop="writeTable"
                >
                  <el-select
                    filterable
                    v-model="item.writeTable"
                    @change="getColumnFn1(item.writeTable,item.linkList)"
                    :placeholder="
                      $t('OfflinedataCollection.label.Datatablename')
                    "
                    :error="$t('OfflinedataCollection.label.Datatablename')"
                  >
                    <el-option
                      v-for="(item, index) in biaoList1"
                      :key="index"
                      :label="item.table_name"
                      :value="item.table_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
            </el-row>
          </el-collapse-item>
          <el-tabs type="border-card">
            <el-tab-pane
              label="源保存字段"
            >

              <el-button
                size="small" @click="childernListAdd(item.childrenTab)">
                新增
              </el-button>
              <el-button
                size="small">
                删除
              </el-button>


              <el-form-item label-width="1">
                <el-table

                  ref="step0Savetable"
                  :data="item.childrenTab"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    label="字段名"
                    prop="targetColumn"
                  >
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.targetColumn"></el-input>
                    </template>

                  </el-table-column>
                  <el-table-column   label="类型"
                                     prop="sourceType">
                    <template slot-scope="scope">
                      <el-select
                        filterable
                        v-model="scope.row.sourceType"
                        @change="setKafkaMainfield(scope.row,item.childrenTab,true)"

                        placeholder="请选择类型"
                        :error="$t('OfflinedataCollection.label.Datatablename')"
                      >
                        <el-option
                          v-for="(item, index) in kafkaChildfieldTypeList"
                          :key="index"
                          :label="item.dictValue"
                          :value="item.dictKey"
                        ></el-option>
                      </el-select>
                    </template>

                  </el-table-column>


                  <el-table-column label="字段注释" prop="targetComment">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.targetComment">

                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-tab-pane>


            <el-tab-pane label="对应关系">
              <el-form-item label-width="1">
                <el-table
                  ref="step2Table"
                  :data="item.linkList"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    label="字段名"
                    prop="name"
                  >
                  </el-table-column>

                  <el-table-column label="字段注释" prop="sourceComment">
                  </el-table-column>

                  <!-- dataType datetime  timestam-->

                  <el-table-column label="对应关系">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.sourceColumn" clearable @change="selectTableComm(scope.row,item.childrenTab)"  >
                        <el-option
                          :label="item.targetColumn"
                          :value="item.targetColumn"
                          v-for="(item, index) in item.childrenTab"
                          :key="index"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-collapse>


      </el-form>


    </div>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import cron from "@/views/components/cron";
import {
  dbListAll,
  getAiModelList,
  getColumn,
  getColumnsByQuerySql,
  syncDb,
} from "@/api/dataAcquisition/offline";

import{kafaColStaus,kafaMainfieldTypes,kafaChildfieldType ,saveKafkaData ,updateKafkaData, getKafkaData, startKafka, stopKafka} from '@/api/dataAcquisition/kafKa'
import Template from '@/views/message/template/list'
import log from '@/views/system/dataInterface/Log'

export default {
  components: {
    Template,
    HeadLayout,
    cron,
  },
  data() {
    return {
      kafkaStatusList:[],
      kafkaMainfieldTypesList:[

      ],
      kafkaChildfieldTypeList:[],
      addform: {
        serverName:'',
        brokerList:'',
        topic:'',
        version:'1.0.0',
        describes:'',
        environment:'',
        status: 3,
        targetDtoList: [], //目标信息数组，包括主集合和子集合
      },
      targetDto: {//目标信息
        slaveCollectName: '',//主集合写死master，子集合为对应集合名称
        writeDatasourceId:'',//目标数据源id
        writeTable:'',//目标表
        linkList: [],//对应关系集合
        sourceList: []//源保存字段集合
      },
      kafkaChildfieldNumber:0,
      kafkaChildfield:[],

      activeNames: ["1", "2"],
      seeTen:false,
      seeText:"查看",
      btLoading: false,
      TimeTypeList: [
        {
          label: "yyyy-MM-dd HH:mm:ss",
          value: "yyyy-MM-dd HH:mm:ss",
        },

        {
          label: "yyyy-MM-dd",
          value: "yyyy-MM-dd",
        },

        {
          label: "MM/dd/yyyy HH:mm:ss",
          value: "MM/dd/yyyy HH:mm:ss",
        },
        {
          label: "HH:mm:ss",
          value: "HH:mm:ss",
        },
        {
          label: "其他",
          value: "1",
        },
      ],

      cronDialogVisible: false,
      timeList: [],
      cron: "",
      step0SaveChecked: [],
      stepsActive: 0,
      showfull: false,
      rules1: {
        serverName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        brokerList: [{ required: true, message: "集群地址", trigger: "blur" }],
        topic: [{ required: true, message: "TOPIC地址", trigger: "blur" }],
      },
      form: {
        readerDatasourceId: "", //步骤1数据库
        writeDatasourceId: "", //步骤2数据库
        readerTables: "", //步骤1表
        writerTables: "", //步骤2表
        readerColumns: [], //步骤1字段
        writerColumns: [], //步骤2字段
        rdbmsReader: {
          querySql: "", //1 sql
          readerSplitPk: "", //1 切分字段
          whereParams: "", //1where
        },
        rdbmsWriter: {
          preSql: "", //2sql,
          postSql: "", //2postSql
        },
      },
      queryTypeList: [
        { label: "等于", value: "1", type: "0" },
        { label: "不等于", value: "2", type: "0" },
        { label: "区间", value: "3", type: "1" },
        { label: "大于", value: "4", type: "0" },
        { label: "小于", value: "5", type: "0" },
        { label: "大于等于", value: "6", type: "0" },
        { label: "小于等于", value: "7", type: "0" },
        { label: "包含", value: "8", type: "1" },
        { label: "不包含", value: "9", type: "1" },
      ],

      step2Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      step3Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dbList: [],
      biaoList: [],
      biaoList1: [],
      colmnList: [],
      colmnList1: [],
      checkAll: false,
      checkedCities: [],
      // isIndeterminate: true,
      isIndeterminate: false,
      checkAll1: false,
      checkedCities1: [],
      // isIndeterminate1: true,
      isIndeterminate1: false,
      threeData: null,
      other: {
        shell: "",
        json: "",
      },
      disabled: true,
      disabled1: true,
    };
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.$route.query.type)) {
        if (this.addform.status == 3) {
          result.push(
            {
              label: this.$t(`cip.cmn.btn.saveBtn`),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
            }
          );
          result.push(
            {
              label: this.$t(`cip.cmn.btn.saveBackBtn`),
              emit: "head-save-back",
              type: "button",
              icon: "",
              btnOptType: 'saveBack',
            }
          );
          if (this.addform.id > 0 && this.addform.status == 3) {
            result.push(
              {
                label: `启动`,
                emit: "head-start",
                type: "button",
                icon: "",
              }
            );
          }
        }
        if (this.addform.status == 1) {
          result.push(
            {
              label: `停止`,
              emit: "head-stop",
              type: "button",
              icon: "",
            }
          );
        }
      }

      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-back",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );

      return result;
    },
  },
  methods: {

    colmnListAdd(){
      this.colmnList.push({id:'',targetColumn:'',sourceType:'',targetComment:'',
          writeDatasourceId:'',
          writeTable:''

        ,childrenTab:[],linkList:[]})
    },
    childernListAdd(tab){
      tab.push({id:'',targetColumn:'',sourceType:'',targetComment:'', writeDatasourceId:'',
        writeTable:''})
    },

    setKafkaMainfield(row,table=this.colmnList,del=false){
      if(!row.targetColumn) {
        row.sourceType=''
        return this.$message.error('请先填写字段名！')
      }
      if(row.sourceType==3){
         row.slaveCollectName=row.targetColumn
        row.writeDatasourceId = this.addform.writeDatasourceId
        this.kafkaChildfield.push(row)
      }else {
        if(!del){
          this.kafkaChildfield.map((item,index)=>{
            if(item.id==row.id){
              this.kafkaChildfield.splice(index,1)
            }
          })
        }

      }
      if(row.sourceType==2){

        table.map(item=>{
          if(item.sourceType==2&&(item.id!=row.id)){
            this.$message.error('只能有一个主键！')
            row.sourceType=''
            return
          }
        })
      }
    },
    isPrimaryKey(table,dYtable){

      if(table.length<1) {
        this.$message.error('请最少添加一条源保存字段数据')
        return false
      }

      let PrimaryKey=null
      table.map(item=>{
        if(item.sourceType==2){
          PrimaryKey=item
        }
      })
      if(!PrimaryKey) {
        this.$message.error('源保存信息中没有主键')

        return false
      }

      let num= 0
      dYtable.map(item=>{
        if(item.sourceColumn!=PrimaryKey.targetColumn){
          num++
        }
      })
      if(num==dYtable.length) {
        this.$message.error('对应关系表中没有选择源信息表主键信息进行对应')
        return false
      }


      return true




    },
    selectTableComm(row,table){


      table.map(item=>{
        if(item.targetColumn==row.sourceColumn){
          row.targetColumn=item.targetColumn
          row.targetComment=item.targetComment
          row.sourceType=item.sourceType
        }
        if(!row.sourceColumn){
          row.targetColumn=''
          row.targetComment=''
        }
      })


    },
    saveKafa(){
      this.$refs.step1Form.validate(async (valid) => {
        if (valid) {
          let error = null
          console.log('this.colmnList:',this.colmnList)
          console.log('this.colmnList1:',this.colmnList1)
          console.log('this.kafkaChildfield:',this.kafkaChildfield)
          error= this.isPrimaryKey(this.colmnList,this.colmnList1)
          if(this.kafkaChildfield.length>0){
            this.kafkaChildfield.map(item=>{
              error= this.isPrimaryKey(item.childrenTab,item.linkList)


            })
          }

          if(!error) return
          //主集合信息
          let targetDto = {}
          targetDto.slaveCollectName = 'master';//主集合写死master，子集合为对应集合名称
          targetDto.writeDatasourceId = this.addform.writeDatasourceId//目标数据源id
          targetDto.writeTable = this.addform.writeTable//目标表
          targetDto.sourceList = []//源保存字段集合
          targetDto.linkList = []//对应关系集合
          // //源保存字段
          this.colmnList.forEach((element) => {
            targetDto.sourceList.push({//源保存字段
              sourceColumn: element.targetColumn,//源字段
              sourceComment: element.targetComment,//源字段描述
              sourceType: element.sourceType//源字段类型
            });
          });
          //对应关系
          this.colmnList1.forEach((element) => {
            if(element.hasOwnProperty('targetColumn')&&element.targetColumn!='') {
              targetDto.linkList.push({//对应关系
                targetColumn: element.name,//目标字段
                sourceColumn: element.targetColumn,//源字段
              });
            }
          });
          this.addform.targetDtoList = []
          this.addform.targetDtoList.push(targetDto)
          console.log('this.addform',this.addform)
          //子集合
          this.kafkaChildfield.forEach((element) => {
            let childTargetDto = {}
            childTargetDto.slaveCollectName = element.slaveCollectName;//主集合写死master，子集合为对应集合名称
            childTargetDto.writeDatasourceId = element.writeDatasourceId//目标数据源id
            childTargetDto.writeTable = element.writeTable//目标表
            childTargetDto.sourceList = []//源保存字段集合
            childTargetDto.linkList = []//对应关系集合
            // //源保存字段
            element.childrenTab.forEach((element1) => {
              childTargetDto.sourceList.push({//源保存字段
                sourceColumn: element1.targetColumn,//源字段
                sourceComment: element1.targetComment,//源字段描述
                sourceType: element1.sourceType//源字段类型
              });
            });
            //对应关系
            element.linkList.forEach((element1) => {
              if(element1.hasOwnProperty('targetColumn')&&element1.targetColumn!='') {
                childTargetDto.linkList.push({//对应关系
                  targetColumn: element1.name,//目标字段
                  sourceColumn: element1.targetColumn,//源字段
                });
              }
            });
            this.addform.targetDtoList.push(childTargetDto)
          });
          console.log('this.addform',this.addform)
          let data={
            ...this.addform
          }

          if (this.$route.query.id) {
            this.addform.id = this.$route.query.id;
            updateKafkaData(data)
              .then((res) => {
                this.$message.success("修改成功");
                this.btLoading = false;
                if (e == "back") {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }
              })
              .catch((res) => {
                this.btLoading = false;
              });
          } else {
            data.status = 3
            this.addform.status = 3
            saveKafkaData(data)
              .then((res) => {
                this.$message.success("保存成功");
                if (e == "back") {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }
                this.btLoading = false;
              })
              .catch((res) => {
                this.btLoading = false;
              });
          }
        }
      })


    },
    start() {
      this.addform.status = 2
      startKafka(this.$route.query.id)
    },
    stop() {
      this.addform.status = 4
      stopKafka(this.$route.query.id)
    },
    changeSee(){
      this.seeTen = !this.seeTen
      if(this.seeTen){
        this.seeText = "折叠"
      }else{
        this.seeText = "查看"
      }
    },
    toback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    send1(e) {
      var data = {
        shou: true,
        msg: "",
      };
      if (this.other.json) {
        data.msg = this.other.json;
        window.parent.postMessage(data, "*");
      } else {
        this.$message({
          message: "点击开始获取信息",
          type: "warning",
        });
      }
    },
    changeQueryType(e, a) {
      a.inputValue = "";
      a.startValue = "";
      a.endValue = "";
    },
    upSteps() {
      this.stepsActive--;
      if (this.stepsActive < 0) {
        this.stepsActive = 0;
      }
    },

    selectstep0Savetable() {
      //保存字段
      this.step0SaveChecked = this.$refs.step0Savetable.selection;
      this.addform.readerPareList = [];
      this.step0SaveChecked.forEach((element) => {
        this.addform.readerPareList.push(element.name);
      });
    },
    nextSteps() {},
    //获取数据库下拉
    getDbTableListFn() {
      dbListAll({}).then((res) => {
        // TODO 目前只支持MySql
        this.dbList = res.data.data.filter(e => e.dbtype === "Mysql");
      });
      kafaColStaus({}).then((res)=>{
        this.kafkaStatusList=res.data.data;

      })
      kafaMainfieldTypes({}).then((res)=>{
        this.kafkaMainfieldTypesList=res.data.data
        console.log( this.kafkaMainfieldTypesList,' this.kafkaMainfieldTypesList')
      })
      kafaChildfieldType({}).then(res=>{
        this.kafkaChildfieldTypeList=res.data.data
      })
    },
    dbChangeFn(value) {
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      this.form.readerTables = "";
      this.getAiModelListFn(obj);
    },
    dbChangeFn1(value) {
      // 选中目标信息后，子目标信息数据库随之改动。 选择得数据表清空
      this.kafkaChildfield.forEach(e => {
        e.writeDatasourceId = value;
        e.writeTable = undefined;
      });
      this.addform.writeTable = undefined
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      this.form.writerTables = "";
      getAiModelList(obj).then((res) => {
        this.biaoList1 = res.data.data;
      });
    },
    //根据数据库获取表
    getAiModelListFn(obj) {
      getAiModelList(obj).then((res) => {
        this.biaoList = res.data.data;
      });
    },
    getColumnFn(value) {
      let source = this.biaoList.find((item) => item.table_name == value);
      let obj = {
        dbtype: this.addform.readerDatasourceId,
        host_id: source.host_id,
        name: source.table_name,
      };
      this.dbList.forEach((element) => {
        if (element.name == this.addform.readerDatasourceId) {
          obj.dbtype = element.dbtype;
        }
      });
      getColumn(obj).then((res) => {
        this.colmnList = res.data.data;
        this.colmnList.forEach((item) => {
          this.$set(item, "inputValue", "");
          this.$set(item, "TimeType", "");
          this.$set(item, "gdtime", "");
          this.$set(item, "DTime", "");
          this.$set(item, "QueryType", "");
          this.$set(item, "startValue", "");
          this.$set(item, "endValue", "");
        });
      });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.colmnList.map((item) => item.name) : [];
      console.log(this.checkedCities);
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colmnList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.colmnList.length;
    },
    getColumnFn1(value,table) {

      let source = this.biaoList1.find((item) => item.table_name == value);
      let obj = {
        dbtype: this.addform.writeDatasourceId,
        host_id: source.host_id,
        name: source.table_name,
      };
      this.dbList.forEach((element) => {
        if (element.name == this.addform.writeDatasourceId) {
          obj.dbtype = element.dbtype;
        }
      });
      getColumn(obj).then((res) => {
        if(table.length>0){
          table.splice(0,table.length)
        }
        res.data.data.map(item=>{
          table.push(item)
        })
        table.forEach((item) => {
          this.$set(item, "sourceColumn", "");
        });

      });
    },

    saveAll(e) {
      this.btLoading = true;
      this.addform.collectColumnList = [];

      // 条件字段
      this.addform.collectWhereList = [];
      let tjchecked = this.$refs.step0Tjtable.selection;
      tjchecked.forEach((element) => {
        this.addform.collectWhereList.push({
          fileldId: element.fileldId,
          fileldName: element.name,
          inputValue: element.inputValue,
          queryType: element.QueryType,
          startValue: element.startValue,
          endValue: element.endValue,
        });
      });

      let step2checked = this.$refs.step2Table.selection;
      step2checked.forEach((element) => {
        this.addform.collectColumnList.push({
          writerColumn: element.name,
          sourceColumn: element.sourceColumn,
        });
      });

      if (this.addform.collectColumnList.length > 0) {
        let canSave = true;
        this.addform.collectColumnList.forEach((element) => {
          if (!element.sourceColumn) {
            canSave = false;
          }
        });

        if (!canSave) {
          this.$message.warning("表所有字段请选择对应关系！");
          this.btLoading = false;
          return;
        }
      }

      let parms = {
        incrTimeFeild: this.addform.incrTimeFeild,
        incrStartTime: this.addform.incrStartTime,
        incrTimeFormat: this.addform.incrTimeFormat,
        name: this.addform.name,
        readerDatasourceId: this.addform.readerDatasourceId,
        writeDatasourceId: this.addform.writeDatasourceId,
        readerTable: this.addform.readerTable,
        writeTable: this.addform.writeTable,
        cron: this.addform.cron,
        rdbmsReaderBo: this.addform.rdbmsReaderBo,
        rdbmsWriterBo: this.addform.rdbmsWriterBo,
        collectColumnList: this.addform.collectColumnList,
        collectWhereList: this.addform.collectWhereList,
        readerPareList: this.addform.readerPareList,
      };

      //  incrTimeFeild: "", //增量时间字段
      //   incrStartTime: "", //开始时间
      //   incrTimeFormat: "", //时间格式
      //   incrTimeFormat2: "", //其他时间格式

      if (this.addform.incrTimeFormat == 1) {
        parms.incrTimeFormat = this.addform.incrTimeFormat2;
      }

      if (this.$route.query.id) {
        parms.id = this.$route.query.id;
        UpdateOffline(parms)
          .then((res) => {
            this.$message.success("修改成功");
            this.btLoading = false;
            if (e == "back") {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          })
          .catch((res) => {
            this.btLoading = false;
          });
      } else {
        SaveOffline(parms)
          .then((res) => {
            this.$message.success("保存成功");
            if (e == "back") {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.btLoading = false;
          })
          .catch((res) => {
            this.btLoading = false;
          });
      }
    },

    handleCheckAllChange1(val) {
      this.checkedCities1 = val ? this.colmnList1.map((item) => item.name) : [];
      this.isIndeterminate1 = false;
    },
    handleCheckedCitiesChange1(value) {
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.colmnList1.length;
      this.isIndeterminate1 =
        checkedCount > 0 && checkedCount < this.colmnList1.length;
    },

    syncDbFn() {
      if (this.showfull) {
        this.threeData.other = "123";
      }
      // this.threeData.other = this.other;
      syncDb(this.threeData).then((res) => {
        this.other = res.data.data;
        this.$message.success("操作成功");
        this.disabled1 = false;
      });
    },
    getColumnsByQuerySqlFn() {
      let obj = {
        querySql: this.addform.rdbmsReaderBo.querySql,
        datasourceId: this.addform.readerDatasourceId,
      };

      getColumnsByQuerySql(obj).then((res) => {
        this.$message.success("解析成功");
        this.$refs.step0Savetable.clearSelection();
        this.checkedCities = res.data.data;
        this.colmnList.forEach((element, index) => {
          this.checkedCities.forEach((element2) => {
            if (element.name == element2) {
              this.$refs.step0Savetable.toggleRowSelection(
                this.colmnList[index],
                true
              );
            }
          });
        });
        this.step0SaveChecked = this.$refs.step0Savetable.selection;
      });
    },
  },
  created() {
    this.getDbTableListFn();
    // this.other = this.$route.query.other;
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      getKafkaData({ id: this.$route.query.id }).then((res) => {
        this.addform = res.data.data;
        console.log('this.addform:',this.addform)
        this.addform.targetDtoList.forEach(e => {
          if(e.slaveCollectName == 'master') {
            //回显主集合信息
            this.addform.writeDatasourceId = e.writeDatasourceId

            this.addform.writeTable = e.writeTable
            //初始化源保存字段
            e.sourceList.forEach(source => {
              let row = {
                targetColumn:source.sourceColumn,
                sourceType:source.sourceType,
                targetComment:source.sourceComment
              };
              this.colmnList.push(row)
            })
          } else {
            //回显子集合信息
            let row = {
              targetColumn:e.slaveCollectName,
              sourceType:3,
              targetComment:'',
              slaveCollectName:e.slaveCollectName,
              writeDatasourceId:e.writeDatasourceId,
              writeTable:e.writeTable,
              childrenTab:[],
              linkList:e.linkList
            }
            //初始化子集合源保存字段
            e.sourceList.forEach(source => {
              let row1 = {
                targetColumn:source.sourceColumn,
                sourceType:source.sourceType,
                targetComment:source.sourceComment
              };
              row.childrenTab.push(row1)
            })

            this.kafkaChildfield.push(row)
          }
        });

        dbListAll({}).then((res) => {
          // TODO 目前只支持MySql
          this.dbList = res.data.data.filter(e => e.dbtype === "Mysql");

          //初始化主表数据库
          let source1 = this.dbList.find(
            (item) => item.name == this.addform.writeDatasourceId
          );
          let obj1 = {
            host_id: source1.name,
            dbType: source1.dbtype,
            id: source1.db_id,
          };
          getAiModelList(obj1).then((res) => {
            this.biaoList1 = res.data.data;
            let source = this.biaoList1.find(
              (item) => item.table_name == this.addform.writeTable
            );
            let obj = {
              dbtype: this.addform.writeDatasourceId,
              host_id: source.host_id,
              name: source.table_name,
            };
            this.dbList.forEach((element) => {
              if (element.name == this.addform.writeDatasourceId) {
                obj.dbtype = element.dbtype;
              }
            });
            getColumn(obj).then((res) => {
              this.colmnList1 = res.data.data;
              this.colmnList1.forEach((item) => {
                this.$set(item, "sourceColumn", "");
              });

              this.$nextTick(() => {
                this.colmnList1.forEach((item, index) => {
                  //回写主集合对应关系
                  this.addform.targetDtoList.forEach(element => {
                    if(element.slaveCollectName == 'master') {
                      element.linkList.forEach(source => {
                        if (item.name == source.targetColumn) {
                          item.sourceColumn = source.sourceColumn;
                          this.$refs.step2Table.toggleRowSelection(
                            this.colmnList1[index],
                            true
                          );
                        }
                      })
                    }
                  })
                });
              });
            });
          });

          //循环初始化子集合数据库和对应关系数据
          this.kafkaChildfield.forEach( e=> {

            let source2 = this.dbList.find(
              (item) => item.name == e.writeDatasourceId
            );
            let obj2 = {
              host_id: source2.name,
              dbType: source2.dbtype,
              id: source2.db_id,
            };
            getAiModelList(obj2).then((res) => {
              this.biaoList1 = res.data.data;
              let source = this.biaoList1.find(
                (item) => item.table_name == this.addform.writeTable
              );
              let obj = {
                dbtype: e.writeDatasourceId,
                host_id: source.host_id,
                name: e.writeTable,
              };
              this.dbList.forEach((element) => {
                if (element.name == e.writeDatasourceId) {
                  obj.dbtype = element.dbtype;
                }
              });
              getColumn(obj).then((res) => {
                let linkList = e.linkList
                e.linkList = res.data.data;
                e.linkList.forEach((item) => {
                  this.$set(item, "sourceColumn", "");
                });

                this.$nextTick(() => {
                  e.linkList.forEach((item, index) => {
                    //回写子集合集合对应关系
                        linkList.forEach(source => {
                          if (item.name == source.targetColumn) {
                            item.sourceColumn = source.sourceColumn;
                          }
                        })
                  });
                });
              });
            });
          })



        });
      });
    }

    this.step0SaveChecked = this.$refs.step0Savetable.selection;
    this.step0SaveChecked.forEach((element) => {
      this.addform.readerPareList.push(element.name);
    });
  },
};
</script>
<style></style>
